






























































































































































































































































































































































































































































































































































































import Vue from 'vue';

export default Vue.extend({
  name: 'Privacy',
  props: {
    inModal: {type: Boolean, required: false, default: false}
  }
})
