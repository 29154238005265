









































































































































































































































































































































































































































































































































































































































































































































































































































































































import Vue from 'vue';

export default Vue.extend({
  name: 'TnC',
  props: {
    inModal: {type: Boolean, required: false, default: false}
  }
})
